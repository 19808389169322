/* Flex container */
.w-full {
  width: 100%;
}

/* Flex container */
.flex {
  display: flex;
}

/* Flex direction */
.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

/* Flex wrapping */
.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* Justify content */
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

/* Align items */
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

/* Align content */
.content-start {
  align-content: flex-start;
}

.content-center {
  align-content: center;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

/* Align self */
.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.uppercase {
  text-transform: uppercase;
}

/* No gap */
.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 4px; /* 0.25rem */
}

/* Medium gaps */
.gap-2 {
  gap: 8px; /* 0.5rem */
}

.gap-3 {
  gap: 12px; /* 0.75rem */
}

/* Larger gaps */
.gap-4 {
  gap: 16px; /* 1rem */
}

.gap-5 {
  gap: 20px; /* 1.25rem */
}

.gap-6 {
  gap: 24px; /* 1.5rem */
}

.gap-7 {
  gap: 28px; /* 1.75rem */
}

/* Extra large gaps */
.gap-8 {
  gap: 32px; /* 2rem */
}

.gap-9 {
  gap: 36px; /* 2.25rem */
}

.gap-10 {
  gap: 40px; /* 2.5rem */
}

/* Add more if needed, for example: */
.gap-11 {
  gap: 44px; /* 2.75rem */
}

.gap-12 {
  gap: 48px; /* 3rem */
}

/* Font weight utilities */

/* Thin */
.font-thin {
  font-weight: 100;
}

/* Extra light */
.font-extralight {
  font-weight: 200;
}

/* Light */
.font-light {
  font-weight: 300;
}

/* Normal */
.font-normal {
  font-weight: 400;
}

/* Medium */
.font-medium {
  font-weight: 500;
}

/* Semibold */
.font-semibold {
  font-weight: 600;
}

/* Bold */
.font-bold {
  font-weight: 700;
}

/* Extra bold */
.font-extrabold {
  font-weight: 800;
}

/* Black */
.font-black {
  font-weight: 900;
}

/* Padding Classes */
.p-0 { padding: 0; }
.p-1 { padding: 4px; }
.p-2 { padding: 8px; }
.p-3 { padding: 12px; }
.p-4 { padding: 16px; }
.p-5 { padding: 20px; }
.p-6 { padding: 24px; }

.pt-0 { padding-top: 0; }
.pt-1 { padding-top: 4px; }
.pt-2 { padding-top: 8px; }
.pt-3 { padding-top: 12px; }
.pt-4 { padding-top: 16px; }
.pt-5 { padding-top: 20px; }

.pr-0 { padding-right: 0; }
.pr-1 { padding-right: 4px; }
.pr-2 { padding-right: 8px; }
.pr-3 { padding-right: 12px; }
.pr-4 { padding-right: 16px; }
.pr-5 { padding-right: 20px; }

.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: 4px; }
.pb-2 { padding-bottom: 8px; }
.pb-3 { padding-bottom: 12px; }
.pb-4 { padding-bottom: 16px; }
.pb-5 { padding-bottom: 20px; }

.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 4px; }
.pl-2 { padding-left: 8px; }
.pl-3 { padding-left: 12px; }
.pl-4 { padding-left: 16px; }
.pl-5 { padding-left: 20px; }

.px-0 { padding-left: 0; padding-right: 0; }
.px-1 { padding-left: 4px; padding-right: 4px; }
.px-2 { padding-left: 8px; padding-right: 8px; }
.px-3 { padding-left: 12px; padding-right: 12px; }
.px-4 { padding-left: 16px; padding-right: 16px; }
.px-5 { padding-left: 20px; padding-right: 20px; }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: 4px; padding-bottom: 4px; }
.py-2 { padding-top: 8px; padding-bottom: 8px; }
.py-3 { padding-top: 12px; padding-bottom: 12px; }
.py-4 { padding-top: 16px; padding-bottom: 16px; }
.py-5 { padding-top: 20px; padding-bottom: 20px; }

/* Margin Classes */
.m-0 { margin: 0; }
.m-1 { margin: 4px; }
.m-2 { margin: 8px; }
.m-3 { margin: 12px; }
.m-4 { margin: 16px; }
.m-5 { margin: 20px; }

.mt-0 { margin-top: 0; }
.mt-1 { margin-top: 4px; }
.mt-2 { margin-top: 8px; }
.mt-3 { margin-top: 12px; }
.mt-4 { margin-top: 16px; }
.mt-5 { margin-top: 20px; }

.mr-0 { margin-right: 0; }
.mr-1 { margin-right: 4px; }
.mr-2 { margin-right: 8px; }
.mr-3 { margin-right: 12px; }
.mr-4 { margin-right: 16px; }
.mr-5 { margin-right: 20px; }

.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 4px; }
.mb-2 { margin-bottom: 8px; }
.mb-3 { margin-bottom: 12px; }
.mb-4 { margin-bottom: 16px; }
.mb-5 { margin-bottom: 20px; }

.ml-0 { margin-left: 0; }
.ml-1 { margin-left: 4px; }
.ml-2 { margin-left: 8px; }
.ml-3 { margin-left: 12px; }
.ml-4 { margin-left: 16px; }
.ml-5 { margin-left: 20px; }

.mx-0 { margin-left: 0; margin-right: 0; }
.mx-1 { margin-left: 4px; margin-right: 4px; }
.mx-2 { margin-left: 8px; margin-right: 8px; }
.mx-3 { margin-left: 12px; margin-right: 12px; }
.mx-4 { margin-left: 16px; margin-right: 16px; }
.mx-5 { margin-left: 20px; margin-right: 20px; }

.my-0 { margin-top: 0; margin-bottom: 0; }
.my-1 { margin-top: 4px; margin-bottom: 4px; }
.my-2 { margin-top: 8px; margin-bottom: 8px; }
.my-3 { margin-top: 12px; margin-bottom: 12px; }
.my-4 { margin-top: 16px; margin-bottom: 16px; }
.my-5 { margin-top: 20px; margin-bottom: 20px; }

/* Text Alignment Classes */
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.cursor-pointer {
  cursor: pointer;
}