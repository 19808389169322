.label_switch__container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.label_switch__cont_label {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.label_switch__label {
  cursor: pointer;
  font-weight: 500;
  font-size: 0.8rem;
}
.label_switch__label:hover {
  color: #002eff;
}
.label_switch__switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 17px;
}
.label_switch__switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.label_switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #CFCFCF;
  -webkit-transition: .4s;
  transition: .4s;
}
.label_switch__slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .label_switch__slider {
  background-color: #04C500;
}
input:focus + .label_switch__slider {
  box-shadow: 0 0 1px #04C500;
}
input:checked + .label_switch__slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}
.label_switch__slider.label_switch__round {
  border-radius: 17px;
}
.label_switch__slider.label_switch__round:before {
  border-radius: 50%;
}