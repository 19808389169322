/*Remove next imports, fix with a internal reset*/
@import "assets/css/base.min.css";
@import "assets/css/tailwind_manual.css";

/* MODULES */
/* Refactor to X css arch */
/* ICONS */
.files__delete-file {
  color: #ed1e79;
  cursor: pointer;
  font-size: 2.5rem !important;
}

/*GLOBALS*/
:root {
  /* SHIPERTO */
  --media-sm: 576px;
  --media-md: 768px;
  --media-lg: 992px;
  --media-xl: 1280px;
  --media-xxl: 1400px;
  --color-orange: #EE7628;
  --color-blue-dark: #23203F;
  --color-main-gray: #716F87;
  /* SHIPERTO */ 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}